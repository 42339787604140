.sub_section {
	width:100%;
}


.sub_section_header {
	background-color: white;
	display:flex;
	align-items: center;
}

.sub_section_header_title {
	padding: 15px 0;
	text-align: left;
	
	align-items: center;
}


.sub_section_header_action_wrapper {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.sub_section_header_action {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.a_filter {
	margin-right: 10px;
	margin-top: 20px;
	max-width: 300px;
}

.no_data_available_caption {
	padding: 25px 0px;
	display: block;
}

@media screen and (min-width: 768px) {
	.a_filter {
		margin-top: 0px;
		margin-right: 20px;
		margin-left: 0px;
	}
	.sub_section_header_title {
		display:flex;
		padding: 15px 0px;

	}
	.sub_section_header_action {
		margin-left:30px;
	}
}

.sub_section_header_action h6 {
	margin-left:5px;
	user-select: none;
	font-size:0.6875rem;
}

.sub_section_header_action img {
	max-width: 15px;
}

.sub_section_content {
		padding: 25px 0;
}
.sub_section_header_filters {
	display:flex;
	align-items: center;
}

@media screen and (min-width: 1200px) {
	.sub_section_content {
	}		
}