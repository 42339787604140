html, body {
  height:100%;
}

#root {
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6,th {
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
	font-weight: normal;
}

.bg_green {
  background-color: #43A047;
}

.bg_light_green {
  background-color: #4AF270;
}

.bg_amber {
  background-color: #FFB300;
}
#fc_frame {
  z-index: 2147483600 !important;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }

  #fc_frame {
    display:none;
  }
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker__input-container input {
  height: 28px;
  border: solid 0.5px #dde0e2;
  margin-top: 1rem;
  color: #707070;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wrapper {
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .wrapper {
    width: 80%;
    max-width: 1200px;
    margin: auto;
  }
}

.main {
  background-color: #f2f2f2;
}

.divider {
  padding-top: 50px;
}

.grecaptcha-badge {
  display: none;
}

.dv-star-rating {
  font-size: 2rem;
  text-align: center;
}

.drive_content__2twzm {
	display:flex;
}
.drive_project_selector_wrapper__w_4cx {
	display:flex;
	justify-content: space-between;

	margin:auto;
	align-items: center;
}

.drive_project_selector__P00On {
	text-align: right;
	margin-left: auto;
}

.drive_company_logo_wrapper__2m4bP {
	display: none;
}

.drive_company_logo_wrapper__2m4bP img {
	display: block;
}

.drive_main__2haCK {
	background-color: #fff;
	margin:auto;
	
}

.drive_drive_app_header__ejJv- {
	background-color: #f9f9f9;
	margin:auto;
	margin-top:25px;
	text-align: left;
}

.drive_mis_app_header__3EyBW {
	background-color: #f9f9f9;
	margin:auto;
	margin-top:0px;
	text-align: left;
	padding: 0px;
}

.drive_drive_app_header__ejJv- h5 {
	padding:20px 30px 10px;
	font-size:18px;
	font-weight: 600;
}

.drive_drive_app_header__ejJv- p {
	color: #a1a1a1;
	padding:0 30px 30px;
	margin:0;
	font-size: 14px;
}

.drive_container__1HIQ_ {
	margin:auto;
}

.drive_panel_header__iaGLs {
	font-size: 1rem;
	font-weight: 900 !important;
	color:black;
	margin:0;
	color:white;
}

.drive_expansion_panel_title__2ha6i {
	display:flex;
	align-items: center;
	margin-left:10px;
	text-transform: uppercase;
}

.drive_expansion_panel_title__2ha6i h5 {
	margin-left:10px;
	text-align: left;
}

.drive_expansion_panel_vis_icon__1Q1bM {
	max-width: 20px;
}

.drive_sub_section_wrapper__1kyAE {
	width: 100%;
}


.drive_sub_section__Y7l8_ {
	width:100%;
}


.drive_sub_section_header__3gxNc {
	background-color: white;
	display:flex;
	justify-content: space-between;
}

.drive_sub_section_header_title__3dPtu {
	padding: 15px 35px;

}


.drive_sub_section_header_action_wrapper__3fcIh {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.drive_sub_section_header_action__2qtdc {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:30px;
	cursor: pointer;
}

.drive_sub_section_header_action__2qtdc span {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
}

.drive_sub_section_header_action__2qtdc img {
	max-width: 15px;
}

.drive_sub_section_content__3MCBo {
	background-color: #f9f9f9;
	padding:30px;
}

@media screen and (min-width:768px) {
}

@media screen and (min-width:1200px) {
	.drive_company_logo_wrapper__2m4bP {
	display: block;
	}
	.drive_container__1HIQ_ {

		margin:auto;
		padding-bottom:60px;
	}
	.drive_panel_header__iaGLs {
		font-size:20px;
	}

	.drive_report_container__gbeTY {
		padding:30px;
		display:flex;
		align-items: center;
		
		flex-wrap: wrap;
	}

	.drive_report__yGAfR {
		width:50%;
		box-sizing: border-box;
		padding:4px;
	}
}
.bg_green {
	background-color: #52c757;
}

.bg_yellow {
	background-color: #ffb300;
}

.bg_red {
	background-color: #f63b34;
}
.LoginsTracker_sub_section__10ZWz {
	width:100%;
}


.LoginsTracker_sub_section_header__3QTIY {
	background-color: white;
	display:flex;
	align-items: center;
}

.LoginsTracker_sub_section_header_title__3x8vd {

	text-align: left;
	display:flex;
	align-items: center;
}


.LoginsTracker_sub_section_header_action_wrapper__2KVG9 {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.LoginsTracker_sub_section_header_action__1AUWl {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.LoginsTracker_no_data_available_caption__3Sx9L {
	padding: 25px 0px;
	display: block;
}

.LoginsTracker_a_filter__1Wfqi {
	margin-right: 10px;
}

@media screen and (min-width: 768px) {
	.LoginsTracker_a_filter__1Wfqi {
		margin-right: 0;
	}
	.LoginsTracker_sub_section_header_title__3x8vd {
		padding: 15px 0px;

	}
	.LoginsTracker_sub_section_header_action__1AUWl {
		margin-left:30px;
	}
}

.LoginsTracker_sub_section_header_action__1AUWl h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.LoginsTracker_sub_section_header_action__1AUWl img {
	max-width: 15px;
}

.LoginsTracker_sub_section_content__1T9m9 {

		padding:25px 0;
}

.LoginsTracker_sub_section_header_filters__1ywtY {
	display:flex;
	align-items: center;
}

@media screen and (min-width: 1200px) {

}
.CompletionTracker_sub_section__dWvXu {
	width:100%;
}


.CompletionTracker_sub_section_header__5i5VB {
	background-color: white;
	display:flex;
	align-items: center;
}

.CompletionTracker_sub_section_header_title__7t2YN {
	padding: 15px 0;
	text-align: left;
	
	align-items: center;
}


.CompletionTracker_sub_section_header_action_wrapper__3Fo7t {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.CompletionTracker_sub_section_header_action__3fTZV {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.CompletionTracker_a_filter__3w45X {
	margin-right: 10px;
	margin-top: 20px;
	max-width: 300px;
}

.CompletionTracker_no_data_available_caption__3OkAw {
	padding: 25px 0px;
	display: block;
}

@media screen and (min-width: 768px) {
	.CompletionTracker_a_filter__3w45X {
		margin-top: 0px;
		margin-right: 20px;
		margin-left: 0px;
	}
	.CompletionTracker_sub_section_header_title__7t2YN {
		display:flex;
		padding: 15px 0px;

	}
	.CompletionTracker_sub_section_header_action__3fTZV {
		margin-left:30px;
	}
}

.CompletionTracker_sub_section_header_action__3fTZV h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.CompletionTracker_sub_section_header_action__3fTZV img {
	max-width: 15px;
}

.CompletionTracker_sub_section_content__frlaR {
		padding: 25px 0;
}
.CompletionTracker_sub_section_header_filters__jUAWh {
	display:flex;
	align-items: center;
}

@media screen and (min-width: 1200px) {
	.CompletionTracker_sub_section_content__frlaR {
	}		
}
.Leaderboard_sub_section__3lFcC {
	width:100%;
}


.Leaderboard_sub_section_header__296wN {
	background-color: white;
	display:flex;
	align-items: center;
}

.Leaderboard_sub_section_header_title__2ceMY {
	padding: 15px 0px 15px 20px;
	text-align: left;
	align-items: center;
}


.Leaderboard_sub_section_header_action_wrapper__1DmTk {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.Leaderboard_sub_section_header_action__jppAg {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.Leaderboard_sub_section_header_filters__zcKPH {
	display:flex;
	align-items: center;
}

.Leaderboard_a_filter__16wwL {
	margin-right: 10px;
	margin-top: 20px;
	max-width: 300px;
}

.Leaderboard_no_data_available_caption__2ApAx {
	padding: 25px 0px;
	display: block;
}

.Leaderboard_cell_span__2K4ZW {
	overflow: hidden;
	text-overflow: ellipsis;
}


@media screen and (min-width: 768px) {
	.Leaderboard_a_filter__16wwL {
		margin-top: 0px;
		margin-right: 20px;

	}

	.Leaderboard_sub_section_header_title__2ceMY {
		padding: 15px 0px 15px 0px;
		display:flex;

	}
	.Leaderboard_sub_section_header_action__jppAg {
		margin-left:30px;
	}
}

.Leaderboard_sub_section_header_action__jppAg h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.Leaderboard_sub_section_header_action__jppAg img {
	max-width: 15px;
}

.Leaderboard_sub_section_content__2Hgnc {
		padding:25px 0;
}

@media screen and (min-width: 1200px) {

}
.LearnerEngagement_sub_section__3D0KK {
	width:100%;
}


.LearnerEngagement_sub_section_header__2DUlh {
	background-color: white;
	display:flex;
	align-items: center;
}

.LearnerEngagement_sub_section_header_title__2K92B {
	padding: 15px 20px;
	text-align: left;
	align-items: center;
}


.LearnerEngagement_sub_section_header_action_wrapper__2l35X {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.LearnerEngagement_sub_section_header_action__2g5JX {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.LearnerEngagement_sub_section_header_filters__1977- {
	display:flex;
	align-items: center;
}

.LearnerEngagement_a_filter__2ZLUJ {
	margin-right: 10px;
	margin-top: 20px;
}

.LearnerEngagement_no_data_available_caption__230eV {
	padding: 25px 0px;
	display: block;
}

.LearnerEngagement_cell_span__1JDMH {
	overflow: hidden;
	text-overflow: ellipsis;
}


@media screen and (min-width: 768px) {
	.LearnerEngagement_a_filter__2ZLUJ {
		margin-top: 0px;
		margin-right: 20px;

	}

	.LearnerEngagement_sub_section_header_title__2K92B {
		padding: 15px 0px;
		display:flex;

	}
	.LearnerEngagement_sub_section_header_action__2g5JX {
		margin-left:30px;
	}
}

.LearnerEngagement_sub_section_header_action__2g5JX h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.LearnerEngagement_sub_section_header_action__2g5JX img {
	max-width: 15px;
}

.LearnerEngagement_sub_section_content__3y6IE {

		padding:25px 0;
}

@media screen and (min-width: 1200px) {

}
.SkillGapAnalysis_sub_section__2pP0B {
	width:100%;
}


.SkillGapAnalysis_sub_section_header__1Uck- {
	background-color: white;
	display:flex;
	align-items: center;
}

.SkillGapAnalysis_sub_section_header_title__CobHp {
	padding: 15px 20px;
	text-align: left;
	align-items: center;
}


.SkillGapAnalysis_sub_section_header_action_wrapper__1SLrE {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.SkillGapAnalysis_sub_section_header_action__f9qbo {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.SkillGapAnalysis_legend_wrapper__2Qdxq {
	margin-top: 30px;
	display:flex;
	align-items: center;
}

.SkillGapAnalysis_legend__2Y9eM {
	display: flex;
	align-items: center;
}

.SkillGapAnalysis_legend_text__13ERB {
	color: black;
	font-size: 0.7rem;
	margin-left: 10px;
	margin-right: 25px;
}

.SkillGapAnalysis_legend_display__34Wbq {
	font-size: 0.7rem;
}

.SkillGapAnalysis_legend_display__34Wbq .SkillGapAnalysis_legend_yellow__3KXYz{
	background-color: #FFCD6A;
	padding: 5px;
}

.SkillGapAnalysis_legend_display__34Wbq .SkillGapAnalysis_legend_green__3dzqM{
	background-color: #7DE37F;
	padding: 5px;
}

.SkillGapAnalysis_legend_display__34Wbq .SkillGapAnalysis_legend_light_green__3Zp6l{
	background-color: #BCEB7C;
	padding: 5px;
}
.SkillGapAnalysis_legend_display__34Wbq .SkillGapAnalysis_legend_red__2Ho3q{
	background-color: #FF2D4D;
	padding: 5px;
}

.SkillGapAnalysis_legend_yellow__3KXYz{
	background-color: #FFCD6A;
	padding: 5px;
}

.SkillGapAnalysis_legend_green__3dzqM{
	background-color: #7DE37F;
	padding: 5px;
}

.SkillGapAnalysis_legend_light_green__3Zp6l{
	background-color: #BCEB7C;
	padding: 5px;
}
.SkillGapAnalysis_legend_red__2Ho3q{
	background-color: #FF2D4D;
	padding: 5px;
}
.SkillGapAnalysis_sub_section_header_filters__14UcZ {
	display:flex;
	align-items: center;
}

.SkillGapAnalysis_sub_section_header_action__f9qbo h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.SkillGapAnalysis_sub_section_header_action__f9qbo img {
	max-width: 15px;
}

.SkillGapAnalysis_sub_section_content__1I8vl {

		padding:25px 0;
}

.SkillGapAnalysis_a_filter__Be276 {
	margin-right: 10px;
	margin-top: 20px;
}

.SkillGapAnalysis_no_data_available_caption__3pNuP {
	padding: 25px 0px;
	display: block;
}

@media screen and (min-width: 768px) {
	.SkillGapAnalysis_a_filter__Be276 {
		margin-top: 0;
		margin-right: 20px;
	}

	.SkillGapAnalysis_sub_section_header_title__CobHp {
		padding: 15px 0px;
		display:flex;

	}
	.SkillGapAnalysis_sub_section_header_action__f9qbo {
		margin-left:30px;
	}
}

@media screen and (min-width: 1200px) {

}

.ToughQuestionAnalysis_sub_section__33HwZ {
	width:100%;
}


.ToughQuestionAnalysis_sub_section_header__5xs7E {
	background-color: white;
	display:flex;
	align-items: center;
}

.ToughQuestionAnalysis_sub_section_header_title__3JkJp {
	padding: 15px 20px;
	text-align: left;
	display:flex;
	align-items: center;
}


.ToughQuestionAnalysis_sub_section_header_action_wrapper__3oH6y {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.ToughQuestionAnalysis_sub_section_header_action__3LAR3 {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.ToughQuestionAnalysis_question_td__1EY7I {
	min-width:400px;
	text-align: left !important;
}

.ToughQuestionAnalysis_no_data_available_caption__30IQL {
	display: block;
	padding: 25px 0px;
}

.ToughQuestionAnalysis_sub_section_header_action__3LAR3 h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.ToughQuestionAnalysis_sub_section_header_action__3LAR3 img {
	max-width: 15px;
}

.ToughQuestionAnalysis_sub_section_content__3-A8x {
	padding:25px 0;
}


.ToughQuestionAnalysis_a_filter__1DWh7 {
	margin-right: 10px;
}

.ToughQuestionAnalysis_sub_section_header_filters__65XSC {
	display:flex;
	align-items: center;
}

@media screen and (min-width: 768px) {
	.ToughQuestionAnalysis_a_filter__1DWh7 {
		margin-right: 20px;

	}
	.ToughQuestionAnalysis_sub_section_header_title__3JkJp {
		padding: 15px 0px;

	}
	.ToughQuestionAnalysis_sub_section_header_action__3LAR3 {
		margin-left:30px;
	}
}

@media screen and (min-width: 1200px) {

}
.RewardsTracker_sub_section__Tf6Lu {
	width:100%;
}


.RewardsTracker_sub_section_header__26Ixn {
	background-color: white;
	display:flex;
	align-items: center;
}

.RewardsTracker_sub_section_header_title__Y__zo {

	text-align: left;
	display:flex;
	align-items: center;
}


.RewardsTracker_sub_section_header_action_wrapper__q5fvU {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.RewardsTracker_sub_section_header_action__2XASL {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.RewardsTracker_no_data_available_caption__1MhT1 {
	padding: 25px 0px;
	display: block;
}

.RewardsTracker_a_filter__DBcRa {
	margin-right: 10px;
}

@media screen and (min-width: 768px) {
	.RewardsTracker_a_filter__DBcRa {
		margin-right: 0;
	}
	.RewardsTracker_sub_section_header_title__Y__zo {
		padding: 15px 0px;

	}
	.RewardsTracker_sub_section_header_action__2XASL {
		margin-left:30px;
	}
}

.RewardsTracker_sub_section_header_action__2XASL h6 {
	margin-left:5px;
	-webkit-user-select: none;
	        user-select: none;
	font-size:0.6875rem;
}

.RewardsTracker_sub_section_header_action__2XASL img {
	max-width: 15px;
}

.RewardsTracker_sub_section_content__1IGGX {

		padding:25px 0;
}

.RewardsTracker_sub_section_header_filters__3Kaub {
	display:flex;
	align-items: center;
}

@media screen and (min-width: 1200px) {

}
.LearnerJourneyTracker_sub_section__3Xbkl {
  width: 100%;
}

.LearnerJourneyTracker_sub_section_header__30Sav {
  background-color: white;
  display: flex;
  align-items: center;
}

.LearnerJourneyTracker_sub_section_header_title__2uM-1 {
  text-align: left;
  display: flex;
  align-items: center;
}

.LearnerJourneyTracker_sub_section_header_action_wrapper__sy3r_ {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.LearnerJourneyTracker_sub_section_header_action__2ihA9 {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 0px;
  cursor: pointer;
}

.LearnerJourneyTracker_no_data_available_caption__3HAxH {
  padding: 25px 0px;
  display: block;
}

.LearnerJourneyTracker_a_filter__30332 {
  margin-right: 10px;
}

@media screen and (min-width: 768px) {
  .LearnerJourneyTracker_a_filter__30332 {
    margin-right: 0;
  }
  .LearnerJourneyTracker_sub_section_header_title__2uM-1 {
    padding: 15px 0px;
  }
  .LearnerJourneyTracker_sub_section_header_action__2ihA9 {
    margin-left: 30px;
  }
}

.LearnerJourneyTracker_sub_section_header_action__2ihA9 h6 {
  margin-left: 5px;
  -webkit-user-select: none;
          user-select: none;
  font-size: 0.6875rem;
}

.LearnerJourneyTracker_sub_section_header_action__2ihA9 img {
  max-width: 15px;
}

.LearnerJourneyTracker_sub_section_content__ZMa8X {
  padding: 25px 0;
}

.LearnerJourneyTracker_sub_section_header_filters__2bG5N {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1200px) {
}

.TeamReportTracker_sub_section__1MCUP {
  width: 100%;
}

.TeamReportTracker_sub_section_header__2hk1u {
  background-color: white;
  display: flex;
  align-items: center;
}

.TeamReportTracker_sub_section_header_title__VkAuI {
  text-align: left;
  display: flex;
  align-items: center;
}

.TeamReportTracker_sub_section_header_action_wrapper__nip3a {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.TeamReportTracker_sub_section_header_action__zs9FR {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 0px;
  cursor: pointer;
}

.TeamReportTracker_no_data_available_caption__2xlKI {
  padding: 25px 0px;
  display: block;
}

.TeamReportTracker_a_filter__1b3M8 {
  margin-right: 10px;
}

@media screen and (min-width: 768px) {
  .TeamReportTracker_a_filter__1b3M8 {
    margin-right: 0;
  }
  .TeamReportTracker_sub_section_header_title__VkAuI {
    padding: 15px 0px;
  }
  .TeamReportTracker_sub_section_header_action__zs9FR {
    margin-left: 30px;
  }
}

.TeamReportTracker_sub_section_header_action__zs9FR h6 {
  margin-left: 5px;
  -webkit-user-select: none;
          user-select: none;
  font-size: 0.6875rem;
}

.TeamReportTracker_sub_section_header_action__zs9FR img {
  max-width: 15px;
}

.TeamReportTracker_sub_section_content__kv9-I {
  padding: 25px 0;
}

.TeamReportTracker_sub_section_header_filters__2_WjE {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1200px) {
}

.MISLeaderboard_title__2LT7S {
	padding: 20px 30px 0px;
	font-size: 18px;
	font-weight: 600;
	margin:0;
	color:#4f0060;
	/*text-align: center;*/
}

.MISLeaderboard_caption__1gu-4 {
	color:#616161;
	padding: 10px 30px;
	font-size: 0.9rem;
}

.MISLeaderboard_primary_action__12yHC {
	/*text-align: center;*/
	padding: 0px 30px 20px;
}
.PrimaryButton_primary_button__2ZKaX {
	padding: 15px 50px;  
  border-radius: 2px;
  background-color: #F4511E;
  color: white;
  border: transparent;
  font-size: 13px;
  font-weight:bold;
  font-family: 'Montserrat';
  cursor:pointer;
  transition: ease box-shadow 0.4s;
  outline:0;
  -webkit-user-select: none;
          user-select: none;
  display:inline-block;
}

.PrimaryButton_primary_button__2ZKaX:hover {
	box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.13);
}

.PrimaryButton_primary_button__2ZKaX:hover:active {
	-webkit-transform: scale(0.99);
	        transform: scale(0.99);
}
.MISRetention_title__XL4I3 {
	padding: 20px 30px 0px;
	font-size: 18px;
	font-weight: 600;
	margin:0;
	color:#4f0060;
	/*text-align: center;*/
}

.MISRetention_caption__2JVbH {
	color:#616161;
	padding: 10px 30px;
	font-size: 0.9rem;
}

.MISRetention_primary_action__1_RTw {
	/*text-align: center;*/
	padding: 0px 30px 20px;
}
.Drive_loader_wrapper__3Exg9 {
	display: flex;
	position: absolute;
	left: 0;
	width: 100%;
	background-color: white;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.Drive_content__S-D5S {
	flex: 1 0 auto;
	display: flex;
}

.Drive_footer__2lOnt {
	flex-shrink: 0;
}

.Drive_drive_screen__1GwqL {
	height:100%;
	display:flex;
	flex-direction: column;
}
.microskill_keyword__3BrE- {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}
.microskill_keyword__3BrE-::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.LoginScreen_login_screen__cgfhK {
  background-image: url(/static/media/mastero-dashboard-bg-login.4c1be565.png);
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginScreen_card__2rQH3 {
  width: 85%;
  border-radius: 7px;
  /*border-collapse: separate; */
  /*perspective: 1px;*/
  overflow: hidden;
  -webkit-backdrop-filter: blur(38px);
  backdrop-filter: blur(38px);
  box-shadow: 0 22px 16px 0 rgba(0, 0, 0, 0.23);
}

.LoginScreen_login_container__1pmrK {
  display: block;
}

.LoginScreen_login_header__27xpi {
  display: block;
  background-color: #4f0060;
  flex-basis: 40%;
  padding: 25px;
}

.LoginScreen_login_body__1NrYw {
  background-color: #f4f4f4;
  flex-basis: 60%;
  display: block;
}

.LoginScreen_dashboard_title__3gkDg {
  font-family: Montserrat;

  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: 8.3px;
  text-align: left;
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.LoginScreen_drive_login_intro__3ebPP {
  width: 70%;
  display: none;
  margin: auto;
}
.LoginScreen_copyright__3X9CY {
  margin-top: 45px;
  font-size: 0.55rem;
  color: white;
  display: none;
  text-align: left;
}

.LoginScreen_mastero_logo__35X4D {
  display: block;
  margin: auto;
  width: 50%;
  min-width: 210px;
}

.LoginScreen_login_body_padder__2antM {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LoginScreen_input_wrapper__2oQU- {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.LoginScreen_input_wrapper__2oQU- input {
  border-radius: 5px;
  border: solid 1px #ea4e1d;
  background-color: #ffffff;
  padding: 11px 15px;
  margin-top: 10px;
  width: calc(100% - 32px);
  /*width: 100%;*/
  /*max-width: 300px;*/
  display: block;
  font-size: 1.1rem;
}

.LoginScreen_input_wrapper__2oQU- label {
  color: #080037;
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
  display: block;
  font-family: 'Montserrat', sans-serif;
}

.LoginScreen_submit_button__3eNdX {
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.33);
  background-color: #f4511e;
  width: 100%;
  display: block;
  font-size: 0.9rem;
  padding: 25px;
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
  border: none;
  cursor: pointer;
}

.LoginScreen_submit_button_progress__2O1f5 {
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.33);
  background-color: #f4511e;
  width: 100%;
  display: block;
  font-size: 0.9rem;
  padding: 5px;
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
  border: none;
  cursor: pointer;
}

.LoginScreen_form_group__1JLdu {
  margin-left: 0;
  margin-right: auto;
}

.LoginScreen_desktop_only__26Z2q {
  display: none;
}

@media screen and (min-width: 1024px) {
  .LoginScreen_card__2rQH3 {
    width: 60%;
  }

  .LoginScreen_login_container__1pmrK {
    width: 100%;
    height: 500px;
    display: flex;
  }

  .LoginScreen_drive_login_intro__3ebPP {
    display: block;
  }

  .LoginScreen_dashboard_title__3gkDg {
    font-size: 22px;
  }

  .LoginScreen_copyright__3X9CY {
    display: block;
  }

  .LoginScreen_login_header__27xpi {
    padding: 0 25px;
  }

  .LoginScreen_mastero_logo__35X4D {
    margin-top: 45px;
    width: 40%;
  }

  .LoginScreen_login_body_padder__2antM {
    padding: 60px;
  }
}

@media screen and (min-width: 1280px) {
  .LoginScreen_desktop_only__26Z2q {
    display: block;
  }
}

@media screen and (min-width: 1920px) {
  .LoginScreen_desktop_only__26Z2q {
    display: block;
  }
}

