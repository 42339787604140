.login_screen {
  background-image: url('./mastero-dashboard-bg-login.png');
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 85%;
  border-radius: 7px;
  /*border-collapse: separate; */
  /*perspective: 1px;*/
  overflow: hidden;
  -webkit-backdrop-filter: blur(38px);
  backdrop-filter: blur(38px);
  box-shadow: 0 22px 16px 0 rgba(0, 0, 0, 0.23);
}

.login_container {
  display: block;
}

.login_header {
  display: block;
  background-color: #4f0060;
  flex-basis: 40%;
  padding: 25px;
}

.login_body {
  background-color: #f4f4f4;
  flex-basis: 60%;
  display: block;
}

.dashboard_title {
  font-family: Montserrat;

  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: 8.3px;
  text-align: left;
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.drive_login_intro {
  width: 70%;
  display: none;
  margin: auto;
}
.copyright {
  margin-top: 45px;
  font-size: 0.55rem;
  color: white;
  display: none;
  text-align: left;
}

.mastero_logo {
  display: block;
  margin: auto;
  width: 50%;
  min-width: 210px;
}

.login_body_padder {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input_wrapper {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.input_wrapper input {
  border-radius: 5px;
  border: solid 1px #ea4e1d;
  background-color: #ffffff;
  padding: 11px 15px;
  margin-top: 10px;
  width: calc(100% - 32px);
  /*width: 100%;*/
  /*max-width: 300px;*/
  display: block;
  font-size: 1.1rem;
}

.input_wrapper label {
  color: #080037;
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
  display: block;
  font-family: 'Montserrat', sans-serif;
}

.submit_button {
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.33);
  background-color: #f4511e;
  width: 100%;
  display: block;
  font-size: 0.9rem;
  padding: 25px;
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
  border: none;
  cursor: pointer;
}

.submit_button_progress {
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.33);
  background-color: #f4511e;
  width: 100%;
  display: block;
  font-size: 0.9rem;
  padding: 5px;
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
  border: none;
  cursor: pointer;
}

.form_group {
  margin-left: 0;
  margin-right: auto;
}

.desktop_only {
  display: none;
}

@media screen and (min-width: 1024px) {
  .card {
    width: 60%;
  }

  .login_container {
    width: 100%;
    height: 500px;
    display: flex;
  }

  .drive_login_intro {
    display: block;
  }

  .dashboard_title {
    font-size: 22px;
  }

  .copyright {
    display: block;
  }

  .login_header {
    padding: 0 25px;
  }

  .mastero_logo {
    margin-top: 45px;
    width: 40%;
  }

  .login_body_padder {
    padding: 60px;
  }
}

@media screen and (min-width: 1280px) {
  .desktop_only {
    display: block;
  }
}

@media screen and (min-width: 1920px) {
  .desktop_only {
    display: block;
  }
}
