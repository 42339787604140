.bg_green {
	background-color: #52c757;
}

.bg_yellow {
	background-color: #ffb300;
}

.bg_red {
	background-color: #f63b34;
}