.primary_button {
	padding: 15px 50px;  
  border-radius: 2px;
  background-color: #F4511E;
  color: white;
  border: transparent;
  font-size: 13px;
  font-weight:bold;
  font-family: 'Montserrat';
  cursor:pointer;
  transition: ease box-shadow 0.4s;
  outline:0;
  user-select: none;
  display:inline-block;
}

.primary_button:hover {
	box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.13);
}

.primary_button:hover:active {
	transform: scale(0.99);
}