.content {
	display:flex;
}
.project_selector_wrapper {
	display:flex;
	justify-content: space-between;

	margin:auto;
	align-items: center;
}

.project_selector {
	text-align: right;
	margin-left: auto;
}

.company_logo_wrapper {
	display: none;
}

.company_logo_wrapper img {
	display: block;
}

.main {
	background-color: #fff;
	margin:auto;
	
}

.drive_app_header {
	background-color: #f9f9f9;
	margin:auto;
	margin-top:25px;
	text-align: left;
}

.mis_app_header {
	background-color: #f9f9f9;
	margin:auto;
	margin-top:0px;
	text-align: left;
	padding: 0px;
}

.drive_app_header h5 {
	padding:20px 30px 10px;
	font-size:18px;
	font-weight: 600;
}

.drive_app_header p {
	color: #a1a1a1;
	padding:0 30px 30px;
	margin:0;
	font-size: 14px;
}

.container {
	margin:auto;
}

.panel_header {
	font-size: 1rem;
	font-weight: 900 !important;
	color:black;
	margin:0;
	color:white;
}

.expansion_panel_title {
	display:flex;
	align-items: center;
	margin-left:10px;
	text-transform: uppercase;
}

.expansion_panel_title h5 {
	margin-left:10px;
	text-align: left;
}

.expansion_panel_vis_icon {
	max-width: 20px;
}

.sub_section_wrapper {
	width: 100%;
}


.sub_section {
	width:100%;
}


.sub_section_header {
	background-color: white;
	display:flex;
	justify-content: space-between;
}

.sub_section_header_title {
	padding: 15px 35px;

}


.sub_section_header_action_wrapper {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.sub_section_header_action {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:30px;
	cursor: pointer;
}

.sub_section_header_action span {
	margin-left:5px;
	user-select: none;
}

.sub_section_header_action img {
	max-width: 15px;
}

.sub_section_content {
	background-color: #f9f9f9;
	padding:30px;
}

@media screen and (min-width:768px) {
}

@media screen and (min-width:1200px) {
	.company_logo_wrapper {
	display: block;
	}
	.container {

		margin:auto;
		padding-bottom:60px;
	}
	.panel_header {
		font-size:20px;
	}

	.report_container {
		padding:30px;
		display:flex;
		align-items: center;
		
		flex-wrap: wrap;
	}

	.report {
		width:50%;
		box-sizing: border-box;
		padding:4px;
	}
}