html, body {
  height:100%;
}

#root {
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6,th {
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
	font-weight: normal;
}

.bg_green {
  background-color: #43A047;
}

.bg_light_green {
  background-color: #4AF270;
}

.bg_amber {
  background-color: #FFB300;
}
#fc_frame {
  z-index: 2147483600 !important;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }

  #fc_frame {
    display:none;
  }
}
