.title {
	padding: 20px 30px 0px;
	font-size: 18px;
	font-weight: 600;
	margin:0;
	color:#4f0060;
	/*text-align: center;*/
}

.caption {
	color:#616161;
	padding: 10px 30px;
	font-size: 0.9rem;
}

.primary_action {
	/*text-align: center;*/
	padding: 0px 30px 20px;
}