.sub_section {
	width:100%;
}


.sub_section_header {
	background-color: white;
	display:flex;
	align-items: center;
}

.sub_section_header_title {
	padding: 15px 20px;
	text-align: left;
	align-items: center;
}


.sub_section_header_action_wrapper {
	display:flex;
	align-items: center;
	padding-right: 15px;
}

.sub_section_header_action {
	display:flex;
	align-items: center;
	margin-right:15px;
	margin-left:0px;
	cursor: pointer;
}

.legend_wrapper {
	margin-top: 30px;
	display:flex;
	align-items: center;
}

.legend {
	display: flex;
	align-items: center;
}

.legend_text {
	color: black;
	font-size: 0.7rem;
	margin-left: 10px;
	margin-right: 25px;
}

.legend_display {
	font-size: 0.7rem;
}

.legend_display .legend_yellow{
	background-color: #FFCD6A;
	padding: 5px;
}

.legend_display .legend_green{
	background-color: #7DE37F;
	padding: 5px;
}

.legend_display .legend_light_green{
	background-color: #BCEB7C;
	padding: 5px;
}
.legend_display .legend_red{
	background-color: #FF2D4D;
	padding: 5px;
}

.legend_yellow{
	background-color: #FFCD6A;
	padding: 5px;
}

.legend_green{
	background-color: #7DE37F;
	padding: 5px;
}

.legend_light_green{
	background-color: #BCEB7C;
	padding: 5px;
}
.legend_red{
	background-color: #FF2D4D;
	padding: 5px;
}
.sub_section_header_filters {
	display:flex;
	align-items: center;
}

.sub_section_header_action h6 {
	margin-left:5px;
	user-select: none;
	font-size:0.6875rem;
}

.sub_section_header_action img {
	max-width: 15px;
}

.sub_section_content {

		padding:25px 0;
}

.a_filter {
	margin-right: 10px;
	margin-top: 20px;
}

.no_data_available_caption {
	padding: 25px 0px;
	display: block;
}

@media screen and (min-width: 768px) {
	.a_filter {
		margin-top: 0;
		margin-right: 20px;
	}

	.sub_section_header_title {
		padding: 15px 0px;
		display:flex;

	}
	.sub_section_header_action {
		margin-left:30px;
	}
}

@media screen and (min-width: 1200px) {

}
