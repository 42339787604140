.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker__input-container input {
  height: 28px;
  border: solid 0.5px #dde0e2;
  margin-top: 1rem;
  color: #707070;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .wrapper {
    width: 80%;
    max-width: 1200px;
    margin: auto;
  }
}

.main {
  background-color: #f2f2f2;
}

.divider {
  padding-top: 50px;
}

.grecaptcha-badge {
  display: none;
}

.dv-star-rating {
  font-size: 2rem;
  text-align: center;
}
