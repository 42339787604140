.loader_wrapper {
	display: flex;
	position: absolute;
	left: 0;
	width: 100%;
	background-color: white;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.content {
	flex: 1 0 auto;
	display: flex;
}

.footer {
	flex-shrink: 0;
}

.drive_screen {
	height:100%;
	display:flex;
	flex-direction: column;
}